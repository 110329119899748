import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { StyledOrderBlock, StyledBottomBlock } from "./style";
import rightArrow from "../../assets/images/right-arrow.png";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDetailsByOrderId, resendMail, uploadVisaDocument } from "../../features/order/orderSlice";
import moment from "moment";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import AddComment from "./AddComment/AddComment";
import MyModal from "../Modal/Modal";
import EmailContent from "./EmailContent/EmailContent";
import ImportingGoodsDetails from "./ImportingGoodsDetails";

const ViewOrders = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const [show, setShow] = useState(false);

  const OrderDetails = useSelector((state) => state?.order?.orderDetails);
  const isLoading = useSelector((state) => state?.order?.loading);

  const [selectedData, setSelectedData] = useState({
    showData: false,
    dataId: 0,
  });

  const [emailId, setEmailId] = useState(OrderDetails?.email);
  const [orderid, setEmailIdOrderid] = useState(OrderDetails?.order_id);
  const [mailServiceProvider, setMailServiceProvider] = useState('AWS');

  const uploadSchema = yup.object({
    email: yup.string().required("Please enter your email"),
    fileUpload: yup.mixed().required("File is required"),
  });

  useEffect(() => {
    if (orderId) {
      dispatch(getOrderDetailsByOrderId(orderId));
    }

    document.title = "Orders Details | Vietnam E-visa";
  }, [dispatch, orderId]);

  useEffect(() => {
    if (
      (OrderDetails &&
        typeof OrderDetails !== "undefined" &&
        OrderDetails?.email &&
        typeof emailId === "undefined") ||
      orderId !== orderid
    ) {
      setEmailId(OrderDetails?.email);
    }
  }, [OrderDetails, orderid]);

  let defaultInitialValues = {
    orderId: OrderDetails?.order_id,
    email: "",
    fileUpload: "",
  };

  if (orderId !== null && orderId !== undefined && OrderDetails !== null) {
    defaultInitialValues = {
      orderId: OrderDetails?.order_id,
      email: OrderDetails?.email,
      fileUpload: "",
    };
  }

  let [initialValues, setInitialValues] = useState(defaultInitialValues);

  const { values, errors, handleBlur, handleSubmit, handleChange, setFieldValue, resetForm } = useFormik({
    initialValues: orderId !== null ? defaultInitialValues : initialValues,
    enableReinitialize: true,
    validationSchema: uploadSchema,
    onSubmit: (values) => {
      console.log("value ",values);
      const data = new FormData();
      data.append("orderId", values.orderId);
      data.append("email", values.email);
      data.append("uploadVisaDoc", values.fileUpload);
      data.append("mailServiceProvider", mailServiceProvider);
      dispatch(uploadVisaDocument(data)).unwrap().then((res) => {
        resetForm();
        if (res.status === 1) {
          toast.success(`${res.message}`, {
            className: "toast-message",
          });
          dispatch(getOrderDetailsByOrderId(orderId));
        }
      });
    },
  });

  const handleResendMail = (e, serviceProvider) => {
    e.preventDefault();
    setMailServiceProvider(serviceProvider);
    const data = {
      orderId: OrderDetails?.order_id,
      email: emailId,
    };
    dispatch(resendMail(data)).unwrap().then((res) => {
      if (res.status === 1) {
        toast.success(`${res.message}`, {
          className: "toast-message",
        });
        dispatch(getOrderDetailsByOrderId(orderId));
      }
    });
  };

  const goodsOptions = [
    { key: 'Animalsoption', label: '1. Animals, Fish and plants including their products' },
    { key: 'narcoticssoption', label: '2. Narcotics, psychotropic substances, etc.' },
    { key: 'currencyoption', label: '3. Currency equal to 100 million Rupiah or more' },
    { key: 'foreignoption', label: '4. Foreign banknotes equal to 1 billion Rupiah or more' },
    { key: 'cigarettesoption', label: '5. More than 200 cigarettes or 25 cigars or 100 grams of sliced tobacco, and 1 liter of alcoholic beverages' },
    { key: 'purchaseoption', label: '6. Goods purchased abroad with total value more than USD 500.00' },
    { key: 'importedgoodsoption', label: '7. Imported goods not considered as personal effect' },
    { key: 'goodoriginatingsoption', label: '8. Goods originating from Indonesia using BC 3.4' },
  ];

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          {OrderDetails?.mailHistory?.length !== 0 && (
            <StyledOrderBlock>
              <h2>Email History</h2>
              <div className="table-block table-responsive">
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: "5%" }}>#</th>
                      <th style={{ width: "10%" }}>Order ID</th>
                      <th style={{ width: "30%" }}>Download</th>
                      <th style={{ width: "25%" }}>Date & Time</th>
                      <th style={{ width: "30%" }}>Mail Provider</th>
                    </tr>
                  </thead>
                  <tbody>
                    {OrderDetails?.mailHistory?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="font-normal">{item?.order_id}</td>
                          {OrderDetails?.uploadDoc?.length ? (
                            <td>
                              <a
                                href={
                                  OrderDetails?.length !== 0
                                    ? `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PUBLIC_UPLOAD_PATH
                                    }docs/${item?.uploadDoc == null
                                      ? OrderDetails?.uploadDoc[0]?.file1
                                      : item?.uploadDoc.file1
                                    }`
                                    : null
                                }
                                className="blue-btn"
                                download
                                rel="noreferrer"
                              >
                                Download File{" "}
                              </a>
                            </td>
                          ) : (
                            ""
                          )}
                          <td>
                            {moment(item?.create_ts).format(
                              "MM-DD-YYYY h:mm:ss"
                            )}
                          </td>
                          <td>{item?.uploadDoc?.mail_service_provider}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <form className="form-inline">
                  <div className="form-group">
                    <label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="form-control input-class"
                        value={emailId}
                        onChange={(e) => setEmailId(e.target.value)}
                      />
                    </label>
                  </div>
                  <div className="form-group">
                  <button
                      type="button"
                      className="blue-btn"
                      id="download_btn"
                      disabled={isLoading}
                      onClick={(e) => {
                        handleResendMail(e, "AWS");
                      }}
                    >
                      Resend Mail(AWS){" "}
                      <span>
                        <img src={rightArrow} alt="rightArrow" />
                      </span>
                    </button>

                    <button
                      type="button"
                      className="blue-btn"
                      id="download_btn_sendgrid"
                      disabled={isLoading}
                      onClick={(e) => {
                        handleResendMail(e, "Sendgrid");
                      }}
                    >
                      Resend Mail(Sendgrid){" "}
                      <span>
                        <img src={rightArrow} alt="rightArrow" />
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </StyledOrderBlock>
          )}
        </div>
        <div className="col-md-12">
          <StyledOrderBlock>
            <h2>Upload Document</h2>
            <div className="table-block table-responsive fw-mobileview">
              <form onSubmit={handleSubmit}>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ width: "15%" }}>Order ID</td>
                      <td style={{ width: "35%" }} className="font-normal">
                        <input
                          type="text"
                          name="orderId"
                          className="form-control"
                          placeholder="Order ID"
                          disabled
                          value={values?.orderId}
                        />
                      </td>
                      <td style={{ width: "15%" }}>Email ID</td>
                      <td style={{ width: "35%" }}>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          value={values?.email}
                          onChange={handleChange}
                          placeholder="Email"
                        />
                        <p>{errors.email}</p>
                      </td>
                    </tr>

                    <tr>
                      <td>PDF</td>
                      <tr>
                        <td>
                          <label className="file-upload">
                            <input
                              type="file"
                              className=""
                              id="fileUpload"
                              name="fileUpload"
                              accept=".pdf,image/*"
                              onChange={(e) =>
                                setFieldValue(
                                  "fileUpload",
                                  e.currentTarget.files[0]
                                )
                              }
                              onBlur={handleBlur}
                            />
                            <span>Choose File</span>
                          </label>
                          <span className="slcted-file"></span>
                          <p>{errors.fileUpload}</p>
                        </td>
                      </tr>
                    </tr>
                    {OrderDetails?.uploadDoc?.length ? (
                      <td colSpan="2">
                        <a
                          href={
                            OrderDetails?.length !== 0
                              ? `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}docs/${OrderDetails?.uploadDoc[0]?.file1}`
                              : null
                          }
                          className="blue-btn"
                          download
                          rel="noreferrer"
                        >
                          Download File{" "}
                        </a>
                      </td>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
                <br />
                <button
                  type="submit"
                  className="blue-btn"
                  id="submit_btn"
                  disabled={isLoading}
                  onClick={() => setMailServiceProvider('AWS') }
                >
                  Submit (AWS){" "}
                  <span>
                    <img src={rightArrow} alt="rightArrow" />
                  </span>
                </button>
                <button
                  type="submit"
                  className="blue-btn"
                  id="submit_btn_sendgrid"
                  disabled={isLoading}
                  onClick={() => setMailServiceProvider('Sendgrid') }
                >
                  Submit (SendGrid){" "}
                  <span>
                    <img src={rightArrow} alt="rightArrow" />
                  </span>
                </button>
              </form>
            </div>
          </StyledOrderBlock>
        </div>
        <div className="col-md-12">
          <StyledOrderBlock>
            <h2>Order Details</h2>
            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.order_id && (
                    <tr>
                      <td>Order ID:</td>
                      <td>{OrderDetails?.order_id}</td>
                    </tr>
                  )}
                  {OrderDetails?.processing_mode && (
                    <tr>
                      <td>Processing Mode</td>
                      <td>{OrderDetails?.processing_mode}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>
          
          <StyledOrderBlock>
            <h2>Personal Information</h2>
            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.full_name && (
                    <tr>
                      <td>Full Name (In Passport)</td>
                      <td>{OrderDetails?.full_name}</td>
                    </tr>
                  )}
                  {OrderDetails?.passport_number && (
                    <tr>
                      <td>Passport Number</td>
                      <td>{OrderDetails?.passport_number}</td>
                    </tr>
                  )}
                  {OrderDetails?.nationality && (
                    <tr>
                      <td>Nationality/Citizenship</td>
                      <td>{OrderDetails?.nationality}</td>
                    </tr>
                  )}
                  {OrderDetails?.dob && (
                    <tr>
                      <td>Date of Birth</td>
                      <td>{OrderDetails?.dob}</td>
                    </tr>
                  )}
                  {OrderDetails?.occupation && (
                    <tr>
                      <td>Occupation</td>
                      <td>{OrderDetails?.occupation}</td>
                    </tr>
                  )}
                  {OrderDetails?.address_in_indonesia && (
                    <tr>
                      <td>Address in Indonesia - hotel name / residence address</td>
                      <td>{OrderDetails?.address_in_indonesia}</td>
                    </tr>
                  )}
                  {OrderDetails?.place_of_arrival && (
                    <tr>
                      <td>Place of Arrival</td>
                      <td>{OrderDetails?.place_of_arrival}</td>
                    </tr>
                  )}
                  {OrderDetails?.flight_voyage && (
                    <tr>
                      <td>Flight / Voyage / Other Carrier Number</td>
                      <td>{OrderDetails?.flight_voyage}</td>
                    </tr>
                  )}
                  {OrderDetails?.doa && (
                    <tr>
                      <td>Date of Arrival (DD-MM-YYYY)</td>
                      <td>{OrderDetails?.doa}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>

          <StyledOrderBlock>
            <h2>Contact Information</h2>
            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.email && (
                    <tr>
                      <td>E-mail</td>
                      <td>{OrderDetails?.email}</td>
                    </tr>
                  )}
                  {OrderDetails?.confirm_email && (
                    <tr>
                      <td>Confirm E-mail</td>
                      <td>{OrderDetails?.confirm_email}</td>
                    </tr>
                  )}
                  {OrderDetails?.country_code && OrderDetails?.phone_number && (
                    <tr>
                      <td>Phone Number</td>
                      <td>{`+ ${OrderDetails?.country_code} ${OrderDetails?.phone_number}`}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>
          <StyledOrderBlock>
            <h2>Additional Data</h2>
            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.accompanied_baggage && (
                    <tr>
                      <td>Number of Accompanied baggage (example: 3 Baggages) </td>
                      <td>
                        {OrderDetails?.accompanied_baggage}
                      </td>
                    </tr>
                  )}
                  {OrderDetails?.unaccompanied_baggage_select && (
                    <tr>
                      <td>Unaccompanied Baggage</td>
                      <td>
                        {OrderDetails?.unaccompanied_baggage_select}
                      </td>
                    </tr>
                  )}
                  {OrderDetails?.unaccompanied_baggage && (
                    <tr>
                      <td>How many Baggage ? </td>
                      <td>
                        {OrderDetails?.unaccompanied_baggage}
                      </td>
                    </tr>
                  )}
                  {OrderDetails?.family_members_travelling && (
                    <tr>
                      <td>Number of Family Members travelling with You (only for passenger) </td>
                      <td>
                        {OrderDetails?.family_members_travelling}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>
          {typeof OrderDetails?.familyMember != "undefined" && OrderDetails?.familyMember.length > 0 && (
            <StyledOrderBlock>
              <h2>Family Information</h2>
              <div className="table-block table-responsive">
                <table>
                  <tbody>
                  { typeof OrderDetails?.familyMember != "undefined" && OrderDetails?.familyMember.map((familyDetails, index) => (
                    familyDetails && (
                      <>
                        <tr>
                          <td>Family Member {index+1}</td>
                        </tr>
                        <br/>
                        {familyDetails?.family_member_name && (
                          <tr>
                            <td>Name</td>
                            <td>{familyDetails?.family_member_name}</td>
                          </tr>
                        )}
                        {familyDetails?.family_passport_number && (
                          <tr>
                            <td>Passport Number</td>
                            <td>{familyDetails?.family_passport_number}</td>
                          </tr>
                        )}
                        {familyDetails?.family_nationality && (
                          <tr>
                            <td>Nationality</td>
                            <td>{familyDetails?.family_nationality}</td>
                          </tr>
                        )}
                        {familyDetails?.family_other_nationalities && (
                          <tr>
                            <td>Other Nationalities</td>
                            <td>{familyDetails?.family_other_nationalities}</td>
                          </tr>
                        )}
                        {familyDetails?.family_relationship && (
                          <tr>
                            <td>Relationship</td>
                            <td>{familyDetails?.family_relationship}</td>
                          </tr>
                        )}
                        <br />
                      </>
                      )
                    ))}
                  </tbody>
                </table>
              </div>
            </StyledOrderBlock>
          )}
          
          {typeof OrderDetails?.importingGoods !== "undefined" && OrderDetails?.importingGoods.length > 0 && (
            <StyledOrderBlock>
              <h2>Importing Goods / Items brought</h2>
              <div className="table-block table-responsive">
                <table>
                  <tbody>
                  {Array.isArray(OrderDetails?.importingGoods) && Array.isArray(goodsOptions) && goodsOptions.map((option, idx) => (
                    <React.Fragment key={idx}>
                      <tr>
                        <td>{option?.label}</td>
                        <td>{OrderDetails?.importingGoods[0][option?.key] === 'yes' ? 'Yes' : 'No'}</td>
                      </tr>
                      {OrderDetails?.importingGoods[0][option.key] === 'yes' &&
                        OrderDetails?.importingGoods.map((details, index) => (
                          <ImportingGoodsDetails key={index} details={details} index={index} />
                        ))}
                    </React.Fragment>
                  ))}
                  </tbody>
                </table>
              </div>
            </StyledOrderBlock>
          )}
          <StyledOrderBlock>
            <h2>Download History</h2>
            <div className="table-block table-responsive">
              <table width="100%">
                <thead>
                  <tr>
                    <th width="5%">#</th>
                    <th width="10%">Order ID</th>
                    <th width="22%">Date & Time</th>
                    <th width="23%">Downloaded File</th>
                    <th width="20%">IP Address</th>
                    <th width="20%">Location</th>
                  </tr>
                </thead>
                <tbody>
                  {OrderDetails?.downloadHistory ? (
                    OrderDetails?.downloadHistory?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item?.order_id}</td>
                          <td>
                            {moment(item?.create_ts).format(
                              "MM-DD-YYYY h:mm:ss"
                            )}{" "}
                            EST
                          </td>
                          <td>
                            {OrderDetails?.uploadDoc?.length !== 0 && (
                              <a
                                href={
                                  OrderDetails?.uploadDoc !== undefined
                                    ? `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}visaDocs/${OrderDetails?.uploadDoc[0]?.file1}`
                                    : null
                                }
                                className="blue-btn"
                                target="_blank"
                                // download
                                rel="noreferrer"
                              >
                                Download File
                              </a>
                            )}
                          </td>
                          <td>{item?.ip}</td>
                          <td>{item?.timezone}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="4" align="center">
                        No record found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <form className="form-inline">
                <div className="form-group">
                  <label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="form-control input-class"
                      value={emailId}
                      onChange={(e) => setEmailId(e.target.value)}
                    />
                  </label>
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="blue-btn"
                    id="download_btn"
                    onClick={handleResendMail}
                    disabled={isLoading}
                  >
                    Sent Mail{" "}
                    <span>
                      <img src={rightArrow} alt="rightArrow" />
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </StyledOrderBlock>
          <AddComment />
          <StyledOrderBlock>
            <h2>Comment History</h2>
            <div className="table-block table-responsive">
              <table>
                <thead>
                  <tr>
                    <th width="10%">#</th>
                    <th width="20%">Order ID</th>
                    <th width="20%">Date & Time</th>
                    <th width="15%">Team</th>
                    <th width="35%">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {typeof OrderDetails !== "undefined" &&
                    OrderDetails.remark ? (
                    OrderDetails.remark?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{item?.order_id}</td>
                          <td>
                            {moment(item?.us_date).format("DD-MM-YYYY")}
                            {"  "}
                            {item?.us_time}
                          </td>
                          <td>{item?.remark_name}</td>
                          <td>
                            {selectedData.showData &&
                              selectedData.dataId === item.id ? (
                              <div
                                rel="40"
                                className="btn blue-btn"
                                onClick={() =>
                                  setSelectedData({
                                    showData: !selectedData.showData,
                                    dataId: item?.id,
                                  })
                                }
                              >
                                {" "}
                                Hide
                              </div>
                            ) : (
                              <div
                                rel="40"
                                onClick={() =>
                                  setSelectedData({
                                    showData: !selectedData.showData,
                                    dataId: item?.id,
                                  })
                                }
                                className="btn blue-btn"
                              >
                                {" "}
                                Show
                              </div>
                            )}
                            <br />
                            {selectedData.showData &&
                              selectedData.dataId === item.id && (
                                <div id="40">
                                  <textarea
                                    name="remark"
                                    className="form-control"
                                    defaultValue={item?.remark}
                                  ></textarea>
                                  <b>
                                    Whatsapp:
                                    {item?.whatsapp ? item?.whatsapp : "N"}
                                  </b>
                                  <b>
                                    Telephone:
                                    {item?.telephone ? item?.telephone : "N"}
                                  </b>
                                  <b>
                                    Refund:
                                    {item?.status_refund
                                      ? item?.status_refund
                                      : "N"}
                                  </b>
                                  <b>
                                    Voided:
                                    {item?.voided ? item?.voided : "N"}
                                  </b>
                                  <b>
                                    Chargeback:
                                    {item?.chargeback ? item?.chargeback : "N"}
                                  </b>
                                  <b>
                                    ReportError:
                                    {item?.report_error
                                      ? item?.report_error
                                      : "N"}
                                  </b>
                                </div>
                              )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="5" align="center">
                        No record found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>
        </div>

        <StyledBottomBlock>
          <button
            type="button"
            className="blue-btn"
            data-toggle="modal"
            data-target="#refundModal"
            onClick={() => setShow(true)}
          >
            View Email Content{" "}
          </button>

          <MyModal
            show={show}
            close={setShow}
            size={"lg"}
            modalHeadTitle={"Email Content"}
            showFooter={true}
            primaryButtonName={"Close"}
            secondaryButtonName={"Send Whatsapp Message"}
            OrderDetails={OrderDetails}
          >
            <EmailContent />
          </MyModal>
        </StyledBottomBlock>
      </div>
    </>
  );
};

export default ViewOrders;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReactDataTable from "../../../components/ReactDataTable/ReactDataTable";
import { StyledContainer, StyledManageBlock } from "./style";
import { deleteTeamMember, getTeamMemberById, getTeamMemberList, addTeamMember, updateTeamMember, getTeamMemberType } from "../../../features/manageteam/manageTeamSlice";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import Modal from "../../../components/Modal";
import { encryptVal, decryptVal } from "../../../utils/utility";

const ManageTeam = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const TeamMembersList = useSelector(
        (state) => state?.manageTeam?.teamMemberList
    );
    const TeamMemberType = useSelector(
        (state) => state?.manageTeam?.teamMemberType
    );

    const teamMemberData = useSelector((state) => state?.manageTeam?.teamMember);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [selectedData, setSelectedData] = useState([]);
    const { teamMemberId } = useParams();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

    const handleShow = (orderId) => {
        setShow(true);
        setSelectedData(orderId);
    };
    useEffect(() => {
        dispatch(getTeamMemberType());
        if (teamMemberId) {
            dispatch(getTeamMemberById(teamMemberId));
        }
        dispatch(getTeamMemberList()).unwrap().then((res) => {
            if (res.status === 401) {
                toast.error(`${res?.message}`, { className: "toast-message" });
                localStorage.removeItem("user");
                navigate("/");
            }
        });
        document.title = "Manage Team | Philippine E-Visa";
    }, [dispatch, navigate, teamMemberId]);

    let defaultInitialValues = {
        name: "",
        email: "",
        password: "",
        type: "",
    };

    const teamMemberSchema = yup.object({
        name: yup.string().required("Please enter your name."),
        email: yup.string().email().required("Please enter your email."),
        password: yup.string().required("Please enter your password."),
        type: yup.string().required("Please select type."),
    });

    if (teamMemberId !== null && teamMemberId !== undefined && teamMemberData !== null) {
        defaultInitialValues = {
            name: teamMemberData?.name ? teamMemberData?.name : "",
            email: teamMemberData?.email ? teamMemberData?.email : "",
            password: teamMemberData?.password ? teamMemberData?.password : "",
            type: teamMemberData?.type ? (teamMemberData?.type) : "",
        };
    }

    let [initialValues, setInitialValues] = useState(defaultInitialValues);

    const tableData = TeamMembersList;

    const columns = [
        {
            name: "#",
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: "Name",
            selector: (row) => row?.name,
            sortable: true,
        },
        {
            name: "Email",
            selector: (row) => row?.email,
            sortable: true,
        },
        {
            name: "Password",
            selector: (row) => row?.password,
            sortable: true,
        },
        {
            name: "Type",
            selector: (row) => row?.memberRole,
            sortable: true,
        },
        {
            name: "Action",
            width: "18rem",
            selector: (row) =>
                TeamMembersList?.length !== 0 ? (
                    <span>
                        <Link
                            to={`/admin/manage-team/${row?.id}`}
                            style={{
                                border: "1px solid #ce1127",
                                borderRadius: "5px",
                                padding: " 0 5px",
                                minWidth: "80px",
                                display: "inline-block",
                                textAlign: "center",
                                height: "40px",
                                lineHeight: "40px",
                                color: "#111",
                                margin: "5px",
                                textDecoration: "none",
                            }}
                            className="blue-border"
                        >
                            Edit
                        </Link>{" "}
                        <Link
                            to="#"
                            onClick={() => handleShow(row?.id)}
                            style={{
                                textDecoration: "none",
                                border: "1px solid #e92a11",
                                borderRadius: "5px",
                                padding: " 0 5px",
                                minWidth: "80px",
                                display: "inline-block",
                                textAlign: "center",
                                height: "40px",
                                lineHeight: "40px",
                                color: " #e92a11",
                                margin: "5px",
                            }}
                        >
                            Delete
                        </Link>
                    </span>
                ) : (" "),
        },
    ];

    const { values, errors, handleBlur, handleChange, handleSubmit, resetForm, setFieldValue } = useFormik({
        initialValues: teamMemberId !== null ? defaultInitialValues : initialValues,
        enableReinitialize: true,
        validationSchema: teamMemberSchema,
        onSubmit: (values) => {
            const data = {
                name: values.name,
                email: values.email,
                password: values.password,
                type: values.type,
            };

            teamMemberId ? dispatch(updateTeamMember({ ...data, tmId: teamMemberId })).unwrap().then((res) => {
                resetForm();
                if (res.status === 1) {
                    dispatch(getTeamMemberList()).unwrap().then((res) => {
                        if (res.status === 401) {
                            toast.error(`${res?.message}`, { className: "toast-message" });
                            localStorage.removeItem("user");
                            navigate("/");
                        }
                    });
                    toast.success(`${res.message}`, { className: "toast-message" });
                } else {
                    toast.error(`${res.message}`, { className: "toast-message" });
                }
            }).catch((err) => {
                toast.error(`${err.message}`, { className: "toast-message" });
            }) : dispatch(addTeamMember(data)).unwrap().then((res) => {
                if (res.status === 1) {
                    resetForm(initialValues);
                    setFieldValue();
                    dispatch(getTeamMemberList()).unwrap().then((res) => {
                        if (res.status === 401) {
                            toast.error(`${res?.message}`, { className: "toast-message" });
                            localStorage.removeItem("user");
                            navigate("/");
                        }
                    });
                    toast.success(`${res.message}`, { className: "toast-message" });
                } else {
                    toast.error(`${res.message}`, { className: "toast-message" });
                }
            }).catch((err) => {
                toast.error(`${err.message}`, { className: "toast-message" });
            });
        },
    });

    const deleteTeamMemberRow = (memberID) => {
        let data = {
            teamMemberId: memberID,
        };
        dispatch(deleteTeamMember(data)).unwrap().then((res) => {
            handleClose();
            if (res[0] !== null) {
                toast.success("Team member deleted successfully.", { className: "toast-message" });
                dispatch(getTeamMemberList()).unwrap().then((res) => {
                    if (res.status === 401) {
                        toast.error(`${res?.message}`, { className: "toast-message" });
                        localStorage.removeItem("user");
                        navigate("/");
                    }
                });
            }
        }).catch((err) => {
            toast.error(`${err.message}`, { className: "toast-message" });
        });
    };

    return (
        <StyledContainer>
            <div className="row">
                <div className="col-md-12">
                    <StyledManageBlock>
                        <h2>Manage Team</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-md-6 pdr-0">
                                    <div className="table-block table-responsive">
                                        <table style={{ width: "100%" }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "30%" }}>Name</td>
                                                    <td style={{ width: "70%" }}>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="name"
                                                            placeholder="Enter Name"
                                                            value={values.name}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        <p>{errors.name}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "30%", background: "none" }}>
                                                        Password
                                                    </td>
                                                    <td style={{ width: "70%", background: "none" }}>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            id="password"
                                                            name="password"
                                                            value={values.password}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            placeholder="Enter Password"
                                                            autoComplete="false"
                                                        />
                                                        <p>{errors.password}</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="col-md-6 pdl-0">
                                    <div className="table-block table-responsive">
                                        <table style={{ width: "100%" }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "30%" }}>Email ID</td>
                                                    <td style={{ width: "70%" }}>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            name="email"
                                                            id="email"
                                                            value={values.email}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            placeholder="Enter Email"
                                                            autoComplete="false"
                                                        />
                                                        <p>{errors.email}</p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td style={{ width: "30%", background: "none" }}>Member Type</td>
                                                    <td style={{ width: "70%", background: "none" }}>
                                                        <select
                                                            name="type"
                                                            className="form-control"
                                                            value={values.type}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        >
                                                            <option>Select Type</option>
                                                            <option value="Admin">Admin</option>
                                                            <option value="Manager">Manager</option>
                                                            <option value="Team">Team</option>
                                                            <option value="Telecaller">Telecaller</option>
                                                            <option value="Night Staff">Night Staff</option>
                                                            {/* {TeamMemberType?.map((type, index) => {
                                                                console.log('type: ', type);
                                                                return (<option value={type?.id} key={type?.id}>{type?.member_role}</option>
                                                                );
                                                            })} */}
                                                        </select>
                                                        <p>{errors.type}</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <button type="submit" className="blue-btn">Submit</button>
                                </div>
                            </div>
                        </form>
                    </StyledManageBlock>
                </div>
                <div className="col-md-12">
                    <StyledManageBlock>
                        <h2>Team Member List</h2>
                        <div className="table-block table-responsive">
                            <ReactDataTable
                                customColumns={columns}
                                customData={tableData}
                                buttonWrapper={null}
                                setPage={setPage}
                                setLimit={setLimit}
                                hideSelectableRows={true}
                                hideSearchInput={true}
                            />
                        </div>
                        <Modal
                            show={show}
                            close={handleClose}
                            size={"s"}
                            confirmAlert={true}
                            noEvent={() => handleClose()}
                            yesEvent={() => deleteTeamMemberRow(selectedData)}
                        ></Modal>
                    </StyledManageBlock>
                </div>
            </div>
        </StyledContainer>
    );
};

export default ManageTeam;

const ImportingGoodsDetails = ({ details, index }) => {
    if (!details) return null;
  
    const renderField = (label, value) => {
      if (value !== null && value !== undefined) {
        return (
          <tr key={label}>
            <td>{label}</td>
            <td>{value}</td>
          </tr>
        );
      }
      return null;
    };
  
    return (
      <>
        <br />
        <tr>
          <td>{index + 1}</td>
        </tr>
        <br />
        {renderField('Value', details?.value)}
        {renderField('Currency', details?.currency)}
        {renderField('Description Of Goods', details?.description_of_Goods)}
        {renderField('Quantity Of Goods', details?.quantityofGoods)}
        {renderField('Units', details?.units)}
        {renderField('Category', details?.category)}
        {renderField('Unaccompanied Baggage', details?.unaccompaniedBagageA ? 'Checked' : null)}
        {renderField('Narcotics Value', details?.value_narcotics)}
        {renderField('Narcotics Currency', details?.currency_narcotics)}
        {renderField('Narcotics Description Of Goods', details?.description_of_Goods_narcotics)}
        {renderField('Narcotics Quantity Of Goods', details?.quantityofGoods_narcotics)}
        {renderField('Narcotics Units', details?.units_narcotics)}
        {renderField('Narcotics Unaccompanied Baggage', details?.unaccompaniedBagage_narcotics ? 'Checked' : null)}
        {renderField('Currency Option', details?.currencyoption)}
        {renderField('Amount of Money (Currency)', details?.amountofmoney_currency)}
        {renderField('Currency (Currency)', details?.currency_currency)}
        {renderField('Currency Description Of Goods', details?.description_of_Goods_currency)}
        {renderField('Currency Unaccompanied Baggage', details?.unaccompaniedBagage_currency ? 'Checked' : null)}
        {renderField('Foreign Option', details?.foreignoption)}
        {renderField('Amount of Money (Foreign)', details?.amountofmoney_foreign)}
        {renderField('Currency (Foreign)', details?.currency_foreign)}
        {renderField('Foreign Description Of Goods', details?.description_of_Goods_foreign)}
        {renderField('Foreign Unaccompanied Baggage', details?.unaccompaniedBagage_foreign ? 'Checked' : null)}
        {renderField('Cigarettes Value', details?.value_cigarettes)}
        {renderField('Cigarettes Currency', details?.currency_cigarettes)}
        {renderField('Cigarettes Description Of Goods', details?.description_of_Goods_cigarettes)}
        {renderField('Cigarettes Quantity Of Goods', details?.quantityofGoods_cigarettes)}
        {renderField('Cigarettes Units', details?.units_cigarettes)}
        {renderField('Cigarettes Unaccompanied Baggage', details?.unaccompaniedBagage_cigarettes ? 'Checked' : null)}
        {renderField('Purchase Value', details?.value_purchase)}
        {renderField('Purchase Currency', details?.currency_purchase)}
        {renderField('Purchase Description Of Goods', details?.description_of_Goods_purchase)}
        {renderField('Purchase Quantity Of Goods', details?.quantityofGoods_purchase)}
        {renderField('Purchase Units', details?.units_purchase)}
        {renderField('Purchase Unaccompanied Baggage', details?.unaccompaniedBagage_purchase ? 'Checked' : null)}
        {renderField('Imported Goods Value', details?.value_importedgood)}
        {renderField('Imported Goods Currency', details?.currency_importedgood)}
        {renderField('Imported Goods Description Of Goods', details?.description_of_Goods_importedgood)}
        {renderField('Imported Goods Quantity Of Goods', details?.quantityofGoods_importedgood)}
        {renderField('Imported Goods Units', details?.units_importedgood)}
        {renderField('Imported Goods Unaccompanied Baggage', details?.unaccompaniedBagage_importedgood ? 'Checked' : null)}
        {renderField('Originating Goods Number', details?.number_goodoriginating)}
        {renderField('Originating Goods Value', details?.value_goodoriginating)}
        {renderField('Originating Goods Currency', details?.currency_goodoriginating)}
        {renderField('Originating Goods Description Of Goods', details?.description_of_Goods_goodoriginating)}
        {renderField('Originating Goods Quantity Of Goods', details?.quantityofGoods_goodoriginating)}
        {renderField('Originating Goods Units', details?.units_goodoriginating)}
        {renderField('Originating Goods Unaccompanied Baggage', details?.unaccompaniedBagage_goodoriginating ? 'Checked' : null)}
        {renderField('Date of Import', details?.date_month_year_import)}
        <br />
      </>
    );
};
  
export default ImportingGoodsDetails;  